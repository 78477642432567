<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="subjects"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button variant="outline-primary" @click="viewDetails(props.row)">
                  <feather-icon class="text-body " icon="EyeIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- view model -->
    <b-modal ref="information-view" hide-footer size="sm" :title="$t('Subject Details')" >
      <table style="border-collapse: separate; border-spacing: 0 15px; ">
        <tr>
          <td>Subject Name</td>
          <td>: {{selected_row.name}}</td>
        </tr>
        <tr>
          <td>Subject Code</td>
          <td>: {{ selected_row.code }}</td>
        </tr>
        <tr>
          <td>Class</td>
          <td>: {{selected_row.classes?selected_row.classes.name:''}}</td>
        </tr>
        <tr>
          <td>Version</td>
          <td>: {{selected_row.version?selected_row.version.name:''}}</td>
        </tr>
        <tr>
          <td>Student Group</td>
          <td>: {{selected_row.student_group?selected_row.student_group.name:''}}</td>
        </tr>
        <tr>
          <td>Has Part ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.has_part)">
            {{ status2[selected_row.has_part] }}
          </b-badge> </td>
        </tr>
        <tr>
          <td>Is Optional ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.is_optional)">
            {{ status2[selected_row.is_optional] }}
          </b-badge> </td>
        </tr>
        <tr>
          <td>Has Mark Effect ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.has_mark_effect)">
            {{ status2[selected_row.has_mark_effect] }}
          </b-badge></td>
        </tr>
        <tr>
          <td>Is Grade Subject ? </td>
          <td>: <b-badge :variant="statusVariant(selected_row.is_grade_subject)">
            {{ status2[selected_row.is_grade_subject] }}
          </b-badge></td>
        </tr>
      </table>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
export default {
  name:'Subject',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      name:'',
      code:'',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Code',
          field: 'code',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Version',
          field: 'version.name',
        },
        {
          label: 'Student Group',
          field: 'student_group.name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status2: {
        1: 'Yes',
        0: 'No',
      },
      subjects: [],
    }
  },
  methods:{
    async getAll() {
      await apiCall
          .get("/student/personal/subjects")
          .then((response) => {
            this.subjects = response.data;
          })
          .catch(() => {
            this.subjects = [];
          });
    },
    viewDetails(row){
      this.selected_row=row;
      this.$refs['information-view'].show()
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getAll();
  },
  watch: {

  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>